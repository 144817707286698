<template>
  <div
    ref="parent"
    class="relative modal-segmentation d-flex white--bg"
  >
    <div
      class="modal-segmentation__close d-flex align-center justify-center"
      @click="$emit('close')"
    >
      <img
        loading="lazy"
        src="@/assets/images/svg/close.svg"
        alt="close"
      >
    </div>
    <div
      v-if="!qTypeResult"
      class="d-flex flex-column modal-segmentation__wrap"
      data-test="segmentation-modal"
    >
      <div class="modal-segmentation__title relative">
        <p class="headline-3">
          Вас интересует?
        </p>
      </div>

      <p
        class="modal-segmentation__chbx"
        data-test="new-connection"
        :class="{ active: qType === 'new' }"
        @click="qType = 'new'"
      >
        <span
          class="white--text d-flex"
          :class="qType === 'new' ? 'primary--bg' : 'white--bg'"
        >
          <span
            class="mask-icon mask-size-lg mask-ischecked"
          /></span>Новое подключение домашнего интернета
      </p>
      <p
        class="modal-segmentation__chbx"
        data-test="current-user"
        :class="{ active: qType === 'old' }"
        @click="qType = 'old'"
      >
        <span
          class="white--text d-flex"
          :class="qType === 'old' ? 'primary--bg' : 'white--bg'"
        >
          <span
            class="mask-icon mask-size-lg mask-ischecked"
          /></span>Вопросы по действующему подключению
        <span class="gray-dark--text">(техподдержка, отключение, оплата)</span>
      </p>
      <d-btn
        :disabled="!qType"
        class="modal-segmentation__submit modal-segmentation__chbx-submit"
        color="primary"
        size="medium"
        data-test="submit"
        @click="selectQType"
      >
        Выбрать
      </d-btn>
    </div>
    <div
      v-else-if="qType === 'old'"
      class="d-flex flex-column modal-segmentation__wrap"
      data-test="segmentation-modal"
    >
      <div class="modal-segmentation__title relative">
        <p class="headline-3">
          Вопросы по действующему подключению
        </p>
      </div>

      <div class="modal-segmentation__support">
        <p>Вы являетесь действующим абонентом {{ provider.name }}</p>
        <p>
          Мы не сможем ответить на вопросы по действующему подключению или
          сменить ваш текущий тариф.
        </p>
        <p>Рекомендуем позвонить по номеру</p>
        <a
          class="secondary-dark--text fwm"
          :href="
            'tel:' + (phone && phone.support ? phone.support.call_number : '')
          "
          @click.prevent="callClick(phone.support.call_number, 'SHOW_SUPPORT')"
        >{{
          phone && phone.support && phone.support.call_number
            ? phone.support.display_number
            : "8 800 ... .. .."
        }}</a>
        <span class="gray-dark--text d-block">Звонок бесплатный по РФ</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'
import { createInteraction } from '~/composible/createInteraction'
import { callsApi } from '~/api'

const props = defineProps({
  target: {
    type: String,
  },
  provider: {},
})
const emit = defineEmits(['close', 'showCallDialog'])

const parent = ref()
const qType = ref()
const qTypeResult = ref()
const interactionsSended = computed(() => mainStore.getInteractionsSended)
const phone = ref()
const segmentationActive = computed(() => mainStore.getSegmentationActive)

const mainStore = useMainStore()
const mainDomain = computed(() => mainStore.getMainDomain)
const oldClientCookie = useCookie('oldClient', { maxAge: 60 * 60 * 24 })
const providerClientCookie = useCookie('providerClient', { maxAge: 60 * 60 * 24 })

const getPhone = async () => {
  phone.value = (
    await callsApi.getCallsGeneral({
      providerId: props.provider!.id,
      domain: mainDomain.value,
    })
  ).data
}

onBeforeMount(async () => {
  if (segmentationActive.value) {
    qType.value = 'old'
    selectQType()
    getPhone()
  }
})
const { cI } = createInteraction()

const callClick = (number: string, type: string) => {
  window.open('tel:' + number, '_self')
  if (!interactionsSended.value) {
    mainStore.$patch({
      interactionsSended: true,
    })
    cI(
      true,
      undefined,
      { provider_id: props.provider?.id },
      { request_type: type },
      undefined,
      undefined,
    )
  }
}
const selectQType = () => {
  if (qType.value === 'old') {
    qTypeResult.value = qType.value
    getPhone()
    if (!interactionsSended.value) {
      mainStore.$patch({
        interactionsSended: true,
      })
      cI(
        true,
        undefined,
        { provider_id: props.provider?.id },
        { request_type: 'SHOW_SUPPORT' },
        undefined,
        undefined,
      )
    }
    mainStore.$patch({
      oldClient: true,
      providerClient: props.provider?.id,
    })
    oldClientCookie.value = true
    providerClientCookie.value = props.provider?.id
  }
  else if (qType.value === 'new') {
    emit('showCallDialog')
    emit('close')
  }
}
</script>

<style scoped lang="scss">
.modal-segmentation {
  width: 600px;
  border-radius: 16px;
  @media (max-width: getBreakpoint(tablet)) {
    border-radius: 0;
  }

  .mask-ischecked {
    mask-image: url('@/assets/images/svg/ischecked.svg');
  }
  &__close {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;
    background: color(gray-darker);
    img {
      opacity: .7;
      transition: opacity .24s;
    }

    &:hover {
      img {
        opacity: 1;
      }
    }
  }
  &__title {
    margin-bottom: 16px;
  }

  &__wrap {
    padding: 56px 24px;
  }

  &__support {
    p {
      font-size: fontSize(headline-8);
      line-height: 1.4;
      margin-bottom: 8px;

      @media (max-width: getBreakpoint(mobile-lg)) {
        font-size: 17px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: fontSize(title);
        line-height: 1.5;
      }
    }
    a {
      font-size: fontSize(headline-5);
      line-height: 1.4;
      transition: color 0.24s;
      &:hover {
        color: color(primary);
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        font-size: 23px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: fontSize(headline-6);
      }
    }
    span {
      font-size: fontSize(label);
      @media (max-width: getBreakpoint(mobile-lg)) {
        font-size: 10px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: fontSize(small);
      }
    }
  }

  &__chbx {
    position: relative;
    padding-left: 32px;
    cursor: pointer;
    margin-bottom: 16px;
    &>span.d-flex {
      position: absolute;
      border-radius: 50%;
      left: 0;
    }

    &:not(.active) {
      & > span {
        border: 1px solid color(gray-p);
      }
    }

    &-submit {
      margin-top: 8px;
      margin-bottom: 16px !important;
      margin-right: auto;
      padding: 16px 32px;
    }
  }

  @media (max-width: getBreakpoint(tablet)) {
    flex-wrap: wrap;
    width: unset;
    &.focus-active {
      .focus-hide {
        display: none;
      }
    }
  }
}
</style>
